
  
  .searchbar {
    width: 450px;
    border: 2px solid #FF00DF;
    padding: 8px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    color: rgb(0, 15, 8);
  }